import { createStore } from "vuex";

export default createStore({
  state: {
    isEng: false,
  },
  getters: {},
  mutations: {
    setIsEng(state, isEng) {
      state.isEng = isEng;
    },
  },
  actions: {},
  modules: {},
});
