<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["isEng"]),
  },
  watch: {
    $route() {
      const isEng = this.$route.name === "english";
      this.setIsEng(isEng);

      if (isEng) {
        document.title = "Telemedicine and remote health monitoring platform";
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            "Telemedicine and remote health monitoring platform"
          );
      } else {
        document.title =
          "Телемедицинская платформа для дистанционного наблюдения за здоровьем";
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            "Телемедицинская платформа для осуществления дистанционного мониторинга пациентов - Гостелемед. Полная информация как подключиться и пользоваться системой врачу и пациенту."
          );
      }
    },
  },
  methods: {
    ...mapMutations(["setIsEng"]),
  },
};
</script>
